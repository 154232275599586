export default function() {
  const lang = 'UKR'
  const yearsList = document.querySelector('#years')
  const yearsButtons = [...yearsList.querySelectorAll('button')]
  const monthsList = document.querySelector('#months')
  const monthsButtons = [...monthsList.querySelectorAll('button')]
  const daysList = document.querySelector('#days')
  let daysButtons = []
  const articlesList = document.querySelector('#articles-list')
  const articles = [...articlesList.querySelectorAll('article')]
  const buttonsControls = [...document.querySelectorAll('.button-controls')]
  const buttonPrev = document.querySelector('#button-prev')
  const buttonNext = document.querySelector('#button-next')
  const today = new Date();
  const nowYear = today.getFullYear();
  const nowMonth = today.getMonth() + 1;
  const nowDate = today.getDate();
  let buttonsListSize;
  let windowWidth = document.documentElement.clientWidth
  if (windowWidth < 768) {
    buttonsListSize = 2
  } else if (windowWidth >= 768 && windowWidth < 1200 ) {
    buttonsListSize = 4
  } else if (windowWidth >= 1200) {
    buttonsListSize = 6
  }
  // --------------------------------------
  // ----------- ARTICLES ------------------
  const hideAllArticles = () => {
    articles.forEach(el => el.classList.add('d-none'))
  }
  const showNeedArticle = (year, month, day) => {
    const article = articles.find(el => el.getAttribute('data-date') === `${day}.${month}.${year}`)
    if (article) {
      article.classList.remove('d-none')
      return article.getAttribute('data-date')
    } 
    const days = new Date(year, month, 0).getDate()
    const arrArticles = []
    for (let i = 1; i <= days; i++) {
      const findedDay = articles.find(el => {
        return el.getAttribute('data-date') === `${i}.${month}.${year}`
      })
      if (findedDay) {
        arrArticles.push(findedDay)
      }
    }     
    if (arrArticles.length !== 0) {
      if (day === 1) {
        arrArticles[0].classList.remove('d-none')
        return arrArticles[0].getAttribute('data-date')
      } 
      arrArticles[arrArticles.length - 1].classList.remove('d-none')
      return arrArticles[arrArticles.length - 1].getAttribute('data-date')
    }
    const artFilter = articles.some(el => {
      return +el.getAttribute('data-date').split('.')[1] > +month
    })
    if (artFilter) {
      articles[0].classList.remove('d-none')
      return articles[0].getAttribute('data-date')
    }
    articles[articles.length - 1].classList.remove('d-none')
    return articles[articles.length - 1].getAttribute('data-date')
  }
  // ---------------------------------------
  // ------------- BUTTONS ------------------
  let monthsArr = []
  if (lang === 'UKR') {
    monthsArr = ['Січня', 'Лютого', 'Березня', 'Квітня', 'Травня', 'Червня', 'Липня', 'Серпня', 'Вересня', 'Жовтня', 'Листопада', 'Грудня']
  } else if (lang === 'ENG') {
    monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  }
  const createMarkup = (year, month) => {
    const days = new Date(year, month, 0).getDate()
    daysList.innerHTML = ''  
    for (let i = 1; i <= days; i++) {
      daysList.insertAdjacentHTML('beforeend', `<button data-day="${i}">${i} ${monthsArr[month - 1]}</button>`)
    }   
    return [...daysList.querySelectorAll('button')]
  }
  // --------------------------------------------------
  // --------------------------------------------------
  const createAttribute = (item, attrName, itterator) => {
    item.forEach((el, index) => {
      if (!itterator) {
        const val = el.innerText
        el.setAttribute(attrName, val)
      } else {
        el.setAttribute(attrName, index + 1)
      }
    })
  }
  // -------------------------------
  // ----------------------------
  const handleAttr = (year, month) => {    
    daysButtons.forEach(btn => {      
      btn.setAttribute('data-year', year)
      btn.setAttribute('data-month', month)
    })
    monthsButtons.forEach(btn => {
      btn.setAttribute('data-year', year)
    })
  }
  // ------------------------------------------

  // ---------------------------------------
  const buttonsDisabler = (buttons) => {
    const arrBtn = []
    buttons.forEach(btn => btn.setAttribute('disabled', true))
    articles.forEach(art => {
      const artDate = art.getAttribute('data-date')
      const artList = buttons.find(btn => {
        const day = btn.getAttribute('data-day')
        const month = btn.getAttribute('data-month')
        const year = btn.getAttribute('data-year')        
        return `${day}.${month}.${year}` === artDate
      })
      if (artList) {
        arrBtn.push(artList)
        arrBtn.forEach(btn => btn.removeAttribute('disabled'))
      }
    })
  }
  // ---------------------------------------
  const activeButton = (activeArtDate ,buttons) => {
    if (activeArtDate) {
      const activeButtonFinded = buttons.find(btn => {
        const dataYear = btn.getAttribute('data-year')
        const dataMonth = btn.getAttribute('data-month')
        const dataDay = btn.getAttribute('data-day')
        
        return activeArtDate === `${dataDay}.${dataMonth}.${dataYear}`
      }) 
      if (activeButtonFinded) {
        buttons.forEach(btn => btn.classList.remove('active'))
        activeButtonFinded.classList.add('active')
        return activeButtonFinded
      } 
    }
    return false
  }
  // -----------------------------------------------
  const hideButtons = (buttons, sizeHide, activeBtn) => {
    buttons.forEach(btn => btn.classList.add('d-none'))
    let showsButtons = []; 
    const days = new Date(nowYear, nowMonth, 0).getDate()    
    if (activeBtn) {
      const activeIndex = +activeBtn.getAttribute('data-day')
      buttons.forEach(btn => btn.classList.add('d-none'))
      if (days > activeIndex + sizeHide) {
        for (let i = activeIndex - 1; i < activeIndex + sizeHide - 1; i++) {
          showsButtons.push(buttons[i])          
        } 
      } else {
        for (let i = activeIndex - 1; i < days - 1; i++) {
          showsButtons.push(buttons[i])
        }
      }
    } else {
      showsButtons = buttons.filter((btn, index) => {
        return index < sizeHide
      })
    }
    showsButtons.forEach(btn => btn.classList.remove('d-none'))
    return showsButtons
  }
  // ----------------------------------------------
  // -------------------------------------------------
  const grupedArticles = (button) => {
    if (button) {
      const buttonYear = button.getAttribute('data-year')
      const buttonMonth = button.getAttribute('data-month')
  
      const years = articles.filter(article => article.getAttribute('data-date').split('.')[2] === buttonYear) 
  
      return years.filter(article => article.getAttribute('data-date').split('.')[1] === buttonMonth)
    }    
    return []
  }

  // -------------------------------------------------
  const init = (year, month, day) => {
    hideAllArticles()
    createAttribute(yearsButtons, 'data-year', false)
    createAttribute(monthsButtons, 'data-month', true)
    daysButtons = createMarkup(year, month, day)    
    handleAttr(year, month)
    buttonsDisabler(daysButtons)
    const activeArticle = showNeedArticle(year, month, day)
    const thisActiveButton = activeButton(activeArticle, daysButtons)
    const atriclesInThisMonths = grupedArticles(thisActiveButton)
    if (+atriclesInThisMonths.length < 2) {
      buttonsControls.forEach(btn => btn.setAttribute('disabled', true))
    } else {
      buttonsControls.forEach(btn => btn.removeAttribute('disabled'))
    }
    hideButtons(daysButtons, buttonsListSize, thisActiveButton)  
    buttonNext.setAttribute('disabled', true)  
    return thisActiveButton
  }
  init(nowYear, nowMonth, nowDate)

  
  // -----------------------------------------------
  const handleButtonListSize = () => {
    windowWidth = document.documentElement.clientWidth
    if (windowWidth < 768) {
      buttonsListSize = 2
    } else if (windowWidth >= 768 && windowWidth < 1200 ) {
      buttonsListSize = 4
    } else if (windowWidth >= 1200) {
      buttonsListSize = 6
    }
    init(nowYear, nowMonth, nowDate)
  }
  window.addEventListener('resize', () => {
    handleButtonListSize()
  })
  window.addEventListener('orientationchange', () => {
    handleButtonListSize()
  })
  // -----------------------------------------------
  const update = () => {
    const articlesCalendar = document.querySelector('.articles-calendar');
    articlesCalendar.addEventListener('click', ({target}) => {
      if (target.tagName.toLowerCase() === 'button' && !target.classList.contains('button-controls') && !target.classList.contains('drop-list__button')) {
        const year = +target.getAttribute('data-year') || 2020
        const month = +target.getAttribute('data-month') || 1
        const day = +target.getAttribute('data-day') || 1
        const activeButtonUpdate = init(year, month, day)
        const atriclesInThisMonths = grupedArticles(activeButtonUpdate)
        if (+atriclesInThisMonths.length < 2) {
          buttonsControls.forEach(btn => btn.setAttribute('disabled', true))
        } else {
          buttonsControls.forEach(btn => btn.removeAttribute('disabled'))
          const btnAttrYear = activeButtonUpdate.getAttribute('data-year')
          const btnAttrMonth = activeButtonUpdate.getAttribute('data-month')
          const btnAttrDay = activeButtonUpdate.getAttribute('data-day')
          const btnDate = `${btnAttrDay}.${btnAttrMonth}.${btnAttrYear}`
          if (btnDate === atriclesInThisMonths[atriclesInThisMonths.length - 1].getAttribute('data-date')) {
            buttonNext.setAttribute('disabled', true)  
          }
          if (btnDate === atriclesInThisMonths[0].getAttribute('data-date')) {
            buttonPrev.setAttribute('disabled', true)  
          }
        }
      }
    }, false)
  }
  update()
  window.addEventListener('click', ({target}) => {
    if (!target.classList.contains('drop-list__button')) {
      const list = [...document.querySelectorAll('.drop-list')]
      list.forEach(item => item.classList.add('d-none'))
    }
  })
  // -----------------------------------------------
  // buttons control
  // ---------------------------------------------------
  const findActiveArticleDate = () => {
    const firstActiveArticle = articles.filter(article => !article.classList.contains('d-none'))[0]
    return firstActiveArticle.getAttribute('data-date')
  }
  const handleClickOnControls = (control) => {
    const activeArticleDate = findActiveArticleDate()
    const activeArticleDateArr = activeArticleDate.split('.')
    const day = +activeArticleDateArr[0]
    const month = +activeArticleDateArr[1]
    const year = +activeArticleDateArr[2]
    daysButtons = createMarkup(year, month, day)
    handleAttr(year, month)
    buttonsDisabler(daysButtons)
    let thisActiveButton = activeButton(activeArticleDate, daysButtons)    
    const atriclesInThisMonths = grupedArticles(thisActiveButton)
    let filtred;
    let lastArt;
    if (control === 'next') {
      filtred = atriclesInThisMonths.find((article) => +activeArticleDate.split('.')[0] <  +article.getAttribute('data-date').split('.')[0]) 
      if (filtred) {
        lastArt = filtred.getAttribute('data-date')
        buttonPrev.removeAttribute('disabled')
      } else {
        lastArt = atriclesInThisMonths[atriclesInThisMonths.length - 1].getAttribute('data-date')
      }
    }
    if (control === 'prev') {
      filtred = atriclesInThisMonths.filter((article) => +activeArticleDate.split('.')[0] > +article.getAttribute('data-date').split('.')[0])
      if (filtred.length !== 0) {
        lastArt = filtred[filtred.length - 1].getAttribute('data-date')
        buttonNext.removeAttribute('disabled')
      } else {        
        lastArt = atriclesInThisMonths[0].getAttribute('data-date')
      }
    }
    atriclesInThisMonths.forEach(art => {
      const artDate = art.getAttribute('data-date')
      if (artDate === lastArt) {
        const atrArr = lastArt.split('.')
        const newDay = atrArr[0]
        const newMonth = atrArr[1]
        const newYear = atrArr[2]
        const newDate = `${newDay}.${newMonth}.${newYear}`
        thisActiveButton = activeButton(newDate, daysButtons)     
        hideAllArticles()
        showNeedArticle(newYear, newMonth, newDay)
        hideButtons(daysButtons, buttonsListSize, thisActiveButton) 
      }     
    })
    if (control === 'next') {
      if (atriclesInThisMonths[atriclesInThisMonths.length - 1].getAttribute('data-date') === filtred.getAttribute('data-date')) {
        buttonNext.setAttribute('disabled', true)
      }  
    }
    if (control === 'prev') {
      if (filtred[filtred.length - 1].getAttribute('data-date') === atriclesInThisMonths[0].getAttribute('data-date')) {
        buttonPrev.setAttribute('disabled', true)
      }
    } 
  }
  // ---------------------------------------------------
  // ---------------------------------------------------
  buttonPrev.addEventListener('click', () => {
    handleClickOnControls('prev')
  })
  buttonNext.addEventListener('click', () => {
    handleClickOnControls('next')
  })
  // ---------------------------------------------------
   // DROPS LISTS
  const dropList = [...document.querySelectorAll('.drop-list')]

  const createButton = (list, year, month) => {
    const buttons = [...list.querySelectorAll('button')];
    const monthsButton = document.querySelector('.drop-list__months') 
    const yearsButton = document.querySelector('.drop-list__years') 
    buttons.forEach(el => {
      if (+el.getAttribute('data-month') === month) {
        monthsButton.innerText = el.innerText
        el.classList.add('active')
      } else if (+el.getAttribute('data-year') === year && !el.getAttribute('data-month')) {
        yearsButton.innerText = el.innerText
        el.classList.add('active')
      }
    });
    list.classList.add('d-none');
    return [...list.parentNode.querySelectorAll('.drop-list__button')]
  }
  
  const showNav = (button) => {
    const list = button.nextElementSibling
    dropList.forEach(item => item.classList.add('d-none'))
    list.classList.remove('d-none')
  } 

  dropList.forEach(element => {
    const selectButton = createButton(element, nowYear, nowMonth)[0]   
    
    selectButton.addEventListener('click', () => {
      showNav(selectButton)
    })
    const btns = element.querySelectorAll('button')
    btns.forEach(el => {      
      el.addEventListener('click', () => {
        btns.forEach(btn => btn.classList.remove('active'))
        el.classList.add('active')
        const button = el.parentNode.parentNode.previousElementSibling;
        button.innerText = el.innerText;
        if (!el.getAttribute('data-month')) {
          const firstButton = [...dropList[0].querySelectorAll('button')]
          firstButton.forEach(item => item.classList.remove('active'))
          firstButton[0].classList.add('active')
          dropList[0].previousElementSibling.innerText = firstButton[0].innerText;
        }
      })
    })
  });
}