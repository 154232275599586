import 'jquery-popup-overlay'

export default (name, settings, afterTime) => {
  $(name).popup(settings);  
  if (afterTime){
    setTimeout(() => {
      $(name).popup('show');
    }, afterTime)
  } 
}

