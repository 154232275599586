// modules
import AOS from 'aos'
import navToggle from './modules/navToggle'
import headerScroll from './modules/headerScroll'
import introVideo from './modules/introVideo'
import projectsTabs from './modules/projectsTabs'
import footerCitiesList from './modules/footerCitiesList'
import articlesCalendar from './modules/articlesCalendar'
import gallery from './modules/gallery'
import popup from "./modules/popup";
import formValidation from "./modules/formValidation";
import anchorsNavigation from "./modules/anchorsNavigation";

document.addEventListener("DOMContentLoaded", () => {
  navToggle('.nav-btn', '#nav', 'nav--active');
  anchorsNavigation()
  introVideo();
  articlesCalendar()
  projectsTabs()
  gallery()
  formValidation()
  const standartPopupSettings = {
    transition: "all 0.3s",
    keepfocus: true,
    autozindex: true,
    closebutton: true,
    blur: true,
  };
  footerCitiesList()
  popup(".modal", standartPopupSettings);
  const presButtonOpen = [...document.querySelectorAll('.presentation_open')]
  const presentation = document.querySelector('#presentation')

  presButtonOpen.forEach(button => {
    button.addEventListener('click', () => {
      const buttonSrc = button.getAttribute('href')
      presentation.querySelector('iframe').setAttribute('src', buttonSrc)
    })
  })
  popup(".presentation", standartPopupSettings);
  AOS.init({
    disable: 'phone',
    once: true,
    duration: 1000,
  })
})

window.addEventListener("scroll", () => {
  headerScroll()
  AOS.refresh()
});
