export default () => {
  const navLinks = [...document.querySelectorAll('.nav__menu a')];
  const joinLinks = [...document.querySelectorAll('a.button--join')];
  const scrollDown = [...document.querySelectorAll('a.scroll-down')];
  const logo = [...document.querySelectorAll('a.logo')];
  const nav = document.querySelector('.nav')

  const scrollTo = (links) => {
    links.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        const target = link.getAttribute('href')
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $(target).offset().top - 92,
            },
            1000
          );
        nav.classList.remove('nav--active')
      })
    })
  }
  scrollTo(navLinks)
  scrollTo(joinLinks)
  scrollTo(scrollDown)
  scrollTo(logo)

  const blocksArr = [];
  navLinks.forEach(link => {
    blocksArr.push(document.querySelector(link.getAttribute('href')))
  })
  
  window.addEventListener("scroll", () => {
    const {scrollTop} = document.documentElement
    const items = blocksArr.filter(el => {
      return el.offsetTop - 100 <= scrollTop
    })
    const activeId = items[items.length - 1].getAttribute('id')
    const activeLink = navLinks.find(el => el.getAttribute('href') === `#${activeId}`)
    navLinks.forEach(el => el.classList.remove('active'))
    activeLink.classList.add('active')    
  });
}
