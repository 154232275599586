import "jquery-validation";
import "jquery-mask-plugin";

export default () => {
  $('input[type="tel"]').mask("+38 000-000-00-00");
  $.validator.addMethod(
    "phoneno",
    (phoneNumber, element) => {
      return (
        this.optional(element) ||
        phoneNumber.match(
          /\+[0-9]{3}\s\([0-9]{2}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}/
        )
      );
    },
    "Введите Ваш телефон"
  );

  const ajaxSend = (formName, data) => {
    $.ajax({
      type: "POST",
      url: "sendmail.php",
      data,
      success() {
        $("#popup-thanks").popup("show");
        setTimeout(() => {
          $(formName).trigger("reset");
        }, 2000);
      },
      error() {
        $("#popup-error").popup("show");
        setTimeout(() => {
          $(formName).trigger("reset");
        }, 2000);
      }
    });
  }
  $.validator.methods.email = ( value ) => {
    return /\S+@\S+\.\S+/.test( value );
  }

  const  formValidation = (index, el) => {
    $(el).addClass(`form-${index}`);

    $(`.form-${index}`).validate({
      rules: {
        name: "required",
        tel: {
          required: true,
          minlength: 17
        },
        email: {
          required: true,
          email: true
        },
        city: "required"
      },
      messages: {
        name: "Дані введені некоректно",
        tel: "Дані введені некоректно",
        email: "Дані введені некоректно",
        city: "Дані введені некоректно",
      },
      submitHandler() {
        const t = $(`.form-${index}`).serialize();
        ajaxSend(`.form-${index}`, t);
      },
    });
  }
  $(".form").each(formValidation);

 
}
