export default function() {
  const playBtn = document.querySelector('.play-intro-video')
  const video = document.querySelector('.intro__video')
  if (playBtn) {
    playBtn.addEventListener('click', () => {
      playBtn.style.display = 'none'
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }, false);
    video.addEventListener('pause', () => {
      playBtn.style.display = 'block'
    }, false);
    video.addEventListener('play', () => {
      playBtn.style.display = 'none'
    }, false);
  }

  const $target = $('.intro__video');
  const targetPos = $target.offset().top;
  const targetHeight = $target.height();
  const winHeight = $(window).height();
  const scrollToElem = targetPos - winHeight;

  function scrollVideo () {
    const winScrollTop = $(this).scrollTop();
    
    if (winScrollTop > scrollToElem && winScrollTop <  scrollToElem + winHeight + targetHeight ) {
      if (!video.paused) {
        video.play();
      }
    } else {
      video.pause();
    }
  }

  window.addEventListener('scroll', scrollVideo);
}