const header = document.querySelector(".header");
const sticky = header.offsetTop;
const headerScrollClass = 'header--scroll'

export default function() {
  if (window.pageYOffset > sticky) {
    header.classList.add(headerScrollClass);
  } else {
    header.classList.remove(headerScrollClass);
  }
}