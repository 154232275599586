// import '@fancyapps/fancybox'
import 'magnific-popup'
import Swiper from 'swiper'

export default () => {
  const colections = document.querySelector('#gallery-collections');
  const blocks = [...colections.querySelectorAll('.gallery__block')]
  const slider = document.querySelector('#gallery-slider');

  const createSliderMarkup = () => {
  
    slider.insertAdjacentHTML('afterbegin', `  
    <div class="swiper-container" id="gallery-swiper">
      <div class="swiper-wrapper">
      </div>
      <div class="swiper-pagination"></div>
    </div>
    `)
    const wrapper = document.querySelector('.swiper-wrapper')
    blocks.forEach((el, index) => {
      if (index % 3 === 0) {
        const slide = document.createElement('div')
        const slide2 = document.createElement('div')
        slide.classList = 'swiper-slide'
        slide2.classList = 'swiper-slide'
        slide.append(el)
        wrapper.append(slide)
        if (blocks.length - 1 !== index) {
          wrapper.append(slide2)
        }        
      }
    })
    const slides = [...wrapper.querySelectorAll('.swiper-slide')]
    const notApend = blocks.filter((el, index) => index % 3 !== 0)
    
    slides.forEach((el, index) => {      
      if (index % 2 === 1){        
        const needApend = notApend.splice(0, 2)
        el.classList.add('swiper-slide--small')
        el.append(needApend[0])
        if (needApend[1]) {
          el.append(needApend[1])
        }
      }
    })    
    return document.querySelector('#gallery-swiper')
  }

  createSliderMarkup()

  
  const swiper = new Swiper('#gallery-swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    // loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3
      }
    }
  });
  swiper.update()

  const createFancyApp = () => {
    blocks.forEach(block => {
      const links = [...block.querySelectorAll('a')]
      const attrFancy = block.getAttribute('data-fancybox')
      const attrCreate = block.getAttribute('data-create')
      
      // block.removeAttribute('data-fancybox')
      // block.removeAttribute('data-create')

      links.forEach(link => {
        link.setAttribute('data-title', attrFancy)
        link.setAttribute('data-Create', attrCreate)
        link.setAttribute('data-caption', `<h3>${attrFancy}</h3><p>${attrCreate}</p>`)
      })
      
      const a = [...block.querySelectorAll('a')]
      a.forEach(item => {
        item.insertAdjacentHTML('beforeend', `
        <div class="gallery__text">
          <h4>${attrFancy}</h4>
          <p>${attrCreate}</p>
          <span>Подивитись все</span>
        </div>
      `)
      })

      $(`.gallery__block[data-fancybox="${attrFancy}"]`).magnificPopup({
        delegate: 'a',
        type: 'image',
        fixedContentPos: true,
        image: {
          markup: `
          <div class="mfp-figure">
            <div class="mfp-top-bar">
              <div class="mfp-title">TEST</div>
              <div class="mfp-close"></div>
            </div>
            <div class="mfp-middle-bar">
              <div class="mfp-img"></div>
            </div>
            <div class="mfp-bottom-bar">
              <div class="mfp-counter"></div>'
            </div>
          </div>`,
          titleSrc() {
              return `
                <h4>${attrFancy}</h4>
                <p>${attrCreate}</p>
              `;
          }
        },
        gallery: { 
          enabled:true ,
          tPrev: 'Попереднє фото',
          tNext: 'Наступне фото', 
          tCounter: '<span class="mfp-counter-span"><span>%curr%</span> / %total%</span>'
        },
        callbacks: {
    
          buildControls() {
            this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
          }
          
        }
      });
    })
  }
  createFancyApp()
}