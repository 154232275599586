export default () => {
  const customSelect = [...document.querySelectorAll('.footer-select__cities')]
  const citiesList = [...document.querySelector('#cities-list').children];
  const buttonSelect = [...document.querySelectorAll('.footer-select__button')]
  const footer = document.querySelector('#footer')

  const cityDisplayHandler = (index) => {
    citiesList.forEach(city => { 
      city.classList.add('d-none')
    })  
    citiesList[index].classList.remove('d-none')
  }
  const activeButton= (button, list) => {
    const buttonsList = [...list.querySelectorAll('button')];
    
    buttonsList.forEach(el => el.classList.remove('active'))
    button.classList.add('active')
  }
  const openSelect = (select) => {
    select.classList.remove('d-none')
  }
  const closeSelect = (select) => {
    select.classList.add('d-none')
  }
  customSelect.forEach(item => {
    const li = [...item.querySelectorAll('button')]
    activeButton(li[0] ,item)    
    li.forEach((button, index) => {
      button.addEventListener('click', () => {
        closeSelect(item)
        const selectButton = item.previousElementSibling
        selectButton.innerText = button.innerText
        cityDisplayHandler(index)
        activeButton(button, item)
      })
    })
  })
  buttonSelect.forEach((button) => {    
    button.addEventListener('click', () => {
      const list = button.parentNode.querySelector('.footer-select__cities')
      if (list.classList.contains('d-none')) {
        openSelect(list)
      } else {
        closeSelect(list)
      }
    })
  })

  const initCustomSelect = (list, index) => {
    list.forEach(el => closeSelect(el))
    cityDisplayHandler(index)
  }

  footer.addEventListener('click', ({target}) => {
    if (!target.classList.contains('footer-select__button')) {
      customSelect.forEach(el => el.classList.add('d-none'))
    }
  })

  initCustomSelect(customSelect, 0)
}