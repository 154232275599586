export default (button, id, toggleClass) => {
  const buttons = document.querySelectorAll(button)
  const nav = document.querySelector(id)

  buttons.forEach(btn => {
    btn.addEventListener('click', () => {
      if (nav.classList.contains(toggleClass)) {
        nav.classList.remove(toggleClass)
      } else {
        nav.classList.add(toggleClass)
      }
    })
  })
}