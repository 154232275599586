export default () => {
  const tabLinks = [...document.querySelectorAll('.projects__tab-link')];
  const tabItems = [...document.querySelectorAll('.projects__tab-item')];
  const tabLinkActiveClass = 'projects__tab-link--active';

  tabLinks[0].classList.add(tabLinkActiveClass)
  tabItems.forEach(el => el.classList.add('d-none'))
  tabItems[0].classList.remove('d-none')

  const activeTab = (el, i) => {
    const findedTab = tabItems.find((tab, index) => index === i)
    tabItems.forEach(item => item.classList.add('d-none'))
    findedTab.classList.remove('d-none')
  }

  tabLinks.forEach((el, i) => {
    el.addEventListener('click', () => {
      tabLinks.forEach(item => item.classList.remove(tabLinkActiveClass));
      el.classList.add(tabLinkActiveClass);
      activeTab(el, i);
    })    
  })
}